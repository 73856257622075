import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { Button, buttonVariants } from "@/components/ui/button";
import { PhoneIcon } from "@/components/landing-page/parts/Icons";
import { SmapyLogo } from "@/components/layout/SmapyLogo";
import { useTranslation } from "next-i18next";
import MobileNavMenu from "./MobileNavMenu";
import Image from "next/image";
import { useState } from "react";
import { useRouter } from "next/router";
import { FACEBOOK_URL, LINKEDIN_URL } from "@/components/misc/urls";
import { SupportPhone } from "@/utils/constants";

export const PageNavigationMenu = () => {
  const { t } = useTranslation("common");
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const router = useRouter();

  console.log("router.pathname: " + router.pathname);
  return (
    <>
      <NavigationMenu className="m-top relative z-10 m-auto hidden py-2 lg:block">
        <div className=" inset-0  bg-white/10 blur-[5px]" />
        <section className="group z-10 mx-auto flex max-w-[1680px] flex-1 list-none items-center justify-center rtl:flex-row-reverse">
          <NavigationMenuItem>
            <Link href="/" legacyBehavior passHref>
              <NavigationMenuLink
                className={`mt-5 ${navigationMenuTriggerStyle()}`}
              >
                <SmapyLogo />
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>

          <NavigationMenuItem className="relative">
            <Link href="/about-us" legacyBehavior passHref>
              <NavigationMenuLink
                className={`hover:bg-gradient-to-r hover:from-[#ff0951] hover:to-[#ff9200] hover:bg-clip-text hover:text-transparent ${
                  router.pathname === "/about-us" && " orange-text-gradient"
                } ${navigationMenuTriggerStyle()}`}
              >
                {t("nav.about")}
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem className="relative">
            <Link href="/faq" legacyBehavior passHref>
              <NavigationMenuLink
                className={`hover:bg-gradient-to-r hover:from-[#ff0951] hover:to-[#ff9200] hover:bg-clip-text hover:text-transparent  ${
                  router.pathname === "/faq" && " orange-text-gradient"
                } ${navigationMenuTriggerStyle()}`}
              >
                {t("nav.faqData")}
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem className="relative">
            <Link href="/prices" legacyBehavior passHref>
              <NavigationMenuLink
                className={`hover:bg-gradient-to-r hover:from-[#ff0951] hover:to-[#ff9200] hover:bg-clip-text hover:text-transparent ${
                  router.pathname === "/prices" && " orange-text-gradient"
                } ${navigationMenuTriggerStyle()}`}
              >
                {t("nav.price")}
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem className="relative">
            <Link href="/security-privacy" legacyBehavior passHref>
              <NavigationMenuLink
                className={`hover:bg-gradient-to-r hover:from-[#ff0951] hover:to-[#ff9200] hover:bg-clip-text hover:text-transparent ${
                  router.pathname === "/security-privacy" &&
                  " orange-text-gradient"
                } ${navigationMenuTriggerStyle()}`}
              >
                {t("nav.security")}
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>

          <div className="grow"></div>

          <NavigationMenuItem>
            <a
              target="_blank"
              href={`tel:${SupportPhone}`}
              rel="noopener noreferrer"
            >
              <PhoneIcon />
            </a>
          </NavigationMenuItem>
          <NavigationMenuItem className="mx-2">
            <Link href="/customer" legacyBehavior passHref>
              <NavigationMenuLink>
                <Button
                  variant="primary-outline"
                  className="w-48 hover:border-0"
                >
                  {t("nav.customer_entry")}
                </Button>
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem className="mx-2">
            <Link href="/advisor" legacyBehavior passHref>
              <NavigationMenuLink>
                <Button
                  variant="secondary-outline"
                  className="w-48 hover:border-0"
                >
                  {t("nav.agent_entry")}
                </Button>
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
        </section>
      </NavigationMenu>
      <MobileNavMenu>
        <div className="lg:hidden">
          <button
            className="absolute left-0 top-0"
            onClick={() => setOpenMobileNav(true)}
          >
            <Image
              src="/images/mobile-menu.svg"
              alt="menu"
              width={110}
              height={100}
            />
          </button>
          <Link href="/" className="absolute right-4 top-4">
            <Image
              src="/images/mobile-logo.svg"
              alt="logo"
              width={220}
              height={100}
            />
          </Link>
        </div>
        {openMobileNav && (
          <nav className="fixed inset-0 z-50 bg-white lg:hidden">
            <button
              className="absolute right-4 top-4 z-50 cursor-pointer"
              onClick={() => setOpenMobileNav(false)}
            >
              <Image src="/images/x.svg" alt="menu" width={30} height={100} />
            </button>
            <Image
              src="/images/open-nav-icon.svg"
              alt="menu"
              width={135}
              height={100}
              className="absolute left-0 top-0"
            />

            <NavigationMenu>
              <section>
                <div className="mt-20 flex list-none flex-col gap-3 text-center">
                  <NavigationMenuItem>
                    <Link href="/about-us" legacyBehavior passHref>
                      <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                      >
                        {t("nav.about")}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <Link href="/faq" legacyBehavior passHref>
                      <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                      >
                        {t("nav.faqData")}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <Link href="/prices" legacyBehavior passHref>
                      <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                      >
                        {t("nav.price")}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <Link href="/security-privacy" legacyBehavior passHref>
                      <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                      >
                        {t("nav.security")}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                </div>
                <div className="mt-6 flex list-none flex-col items-center justify-center gap-5">
                  <NavigationMenuItem className="mx-2 w-full">
                    <Link href="/customer" legacyBehavior passHref>
                      <NavigationMenuLink>
                        <Button variant="primary" className="mx-auto px-1">
                          {t("nav.customer_entry")}
                        </Button>
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                  <NavigationMenuItem className="mx-2 w-full">
                    <Link href="/advisor" legacyBehavior passHref>
                      <NavigationMenuLink>
                        <Button variant="secondary" className="mx-auto px-1">
                          {t("nav.agent_entry")}
                        </Button>
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                </div>
                <div className="mt-10 flex list-none items-center justify-center border-t-[1px] border-gray-200 pt-10 ">
                  <NavigationMenuItem>
                    <a
                      target="_blank"
                      href={FACEBOOK_URL}
                      rel="noopener noreferrer"
                    >
                      <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                      >
                        <Image
                          src="/images/Facebook.svg"
                          alt="facebook"
                          width={42}
                          height={42}
                        />
                      </NavigationMenuLink>
                    </a>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <a
                      target="_blank"
                      href={LINKEDIN_URL}
                      rel="noopener noreferrer"
                    >
                      <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                      >
                        <Image
                          src="/images/Linkedin-ico.svg"
                          alt="linkedin"
                          width={42}
                          height={42}
                        />
                      </NavigationMenuLink>
                    </a>
                  </NavigationMenuItem>
                </div>
                <div className="mt-5 flex list-none flex-col gap-3 text-center">
                  <NavigationMenuItem>
                    <Link href="/terms" legacyBehavior passHref>
                      <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                      >
                        <span className="font-normal text-[#495057]">
                          {t("nav_menu_side.terms")}
                        </span>
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <Link href="/terms#privacy" legacyBehavior passHref>
                      <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                      >
                        <span className="font-normal text-[#495057]">
                          {t("nav_menu_side.privacy")}
                        </span>
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <Link href="/about-us" legacyBehavior passHref>
                      <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                      >
                        <span className="font-normal text-[#495057]">
                          {t("nav_menu_side.about")}
                        </span>
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                </div>
              </section>
            </NavigationMenu>
          </nav>
        )}
      </MobileNavMenu>
    </>
  );
};
