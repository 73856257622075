import { isDev } from "@/utils/nextjs-utils";

export const FACEBOOK_URL = "https://www.facebook.com/smapy.co.il";
export const LINKEDIN_URL =
  "https://www.linkedin.com/company/smapy-smart-insurance-policy/";

class DisplayRef {
  constructor(public display: string, public ref: string) {}
}

class Email extends DisplayRef {
  constructor(email: string) {
    super(email, `mailto:${email}`);
  }
}

class Phone extends DisplayRef {
  constructor(phone: string) {
    super(phone, `tel:+972${phone.replaceAll("-", "").slice(1)}`);
  }
}

export const SMAPY_URL = "https://www.smapy.co.il";
export const SMAPY_URL_TEXT = "www.smapy.co.il";

export const EMAIL_TERMS = new Email("info@smapy.co.il");
export const EMAIL_ACCESSIBILITY = new Email("Jordan.elbaz@smapy.co.il");

export const PHONE_TERMS = new Phone("058-780-3073");
export const PHONE_ACCESSIBILITY = new Phone("054-8800575");
export const TEST_PHONE = "+972523077666";
export const TEST_EMAIL = isDev()
  ? [
      "mulyoved@gmail.com",
      "Jordan.elbaz@smapy.co.il",
      "junaidarshad182@gmail.com",
    ]
  : ["mulyoved@gmail.com", "Jordan.elbaz@smapy.co.il"];
