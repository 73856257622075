import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { LINKEDIN_URL, FACEBOOK_URL } from "@/components/misc/urls";
interface Props {
  backgroundColor?: boolean;
}

export const Footer = ({ backgroundColor }: Props) => {
  const { t } = useTranslation("common");
  return (
    <footer
      className={`relative z-10 -mt-72 bg-[url('/images/mobile-footer-g.png')] bg-cover bg-no-repeat pt-20 sm:-mt-2 sm:pb-20 ${
        backgroundColor && "md:bg-[#C4E4E8]"
      } lg:bg-[url('/images/main-f-bg.png')]`}
    >
      <div className=" grid w-full grid-cols-1 gap-6 px-8 text-white sm:grid-cols-2 md:gap-0 lg:grid-cols-6 lg:px-20 xl:gap-6">
        <div>
          <h2 className="text-xl md:text-2xl">
            {t("footer.mortgage_ins.header")}
          </h2>
          <ul className="mt-2 flex flex-col gap-2 text-sm font-light md:text-base">
            <li>
              <Link href="#">{t("footer.mortgage_ins.list.1")}</Link>
            </li>
            <li>
              <Link href="/compare/mortgage">
                {t("footer.mortgage_ins.list.2")}
              </Link>
            </li>
            <li>
              <Link href="#">{t("footer.mortgage_ins.list.3")}</Link>
            </li>
            <li>
              <Link href="#">{t("footer.mortgage_ins.list.4")}</Link>
            </li>
            <li>
              <Link href="#">{t("footer.mortgage_ins.list.5")}</Link>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl md:text-2xl">
            {t("footer.structure_ins.header")}
          </h2>
          <ul className="mt-2 flex flex-col gap-2 text-sm font-light md:text-base">
            <li>
              <Link href="#">{t("footer.structure_ins.list.1")}</Link>
            </li>
            <li>
              <Link href="/compare/property">
                {t("footer.structure_ins.list.2")}
              </Link>
            </li>
            <li>
              <Link href="#">{t("footer.structure_ins.list.3")}</Link>
            </li>
            <li>
              <Link href="#">{t("footer.structure_ins.list.4")}</Link>
            </li>
            <li>
              <Link href="#">{t("footer.structure_ins.list.5")}</Link>
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl md:text-2xl">{t("footer.life_ins.header")}</h2>
          <ul className="mt-2 flex flex-col gap-2 text-sm font-light md:text-base">
            <li>
              <Link href="#">{t("footer.life_ins.list.1")}</Link>
            </li>
            <li>
              <Link href="/compare/life">{t("footer.life_ins.list.2")}</Link>
            </li>
            <li>
              <Link href="#">{t("footer.life_ins.list.3")}</Link>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl md:text-2xl">{t("footer.license.header")}</h2>
          <ul className="mt-2 flex flex-col gap-2 text-sm font-light md:text-base">
            <li>
              <Link href="#">{t("footer.license.list.1")}</Link>
            </li>
            <li>
              <Link href="#">{t("footer.license.list.2")}</Link>
            </li>
            <li>
              <Link href="#">{t("footer.license.list.3")}</Link>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl md:text-2xl">{t("footer.info.header")}</h2>
          <ul className="mt-2 flex flex-col gap-2 text-sm font-light md:text-base">
            <li>
              <Link href="/faq">{t("footer.info.list.1")}</Link>
            </li>
            <li>
              <Link href="#">{t("footer.info.list.2")}</Link>
            </li>
            <li>
              <Link href="/prices">{t("footer.info.list.3")}</Link>
            </li>
            <li>
              <Link href="/blog">{t("footer.info.list.4")}</Link>
            </li>
            <li>
              <Link href="/contact/phone">{t("footer.info.list.5")}</Link>
            </li>
            <li>
              <Link href="/terms">{t("footer.info.list.7")}</Link>
            </li>
            <li>
              <Link href="/terms#privacy">{t("footer.info.list.8")}</Link>
            </li>
            <li>
              <Link href="/accessibility">{t("footer.info.list.6")}</Link>
            </li>
          </ul>
        </div>

        <div className="md:item-center order-first flex flex-col items-center sm:items-start md:order-last md:justify-center">
          <Link href="/">
            <Image
              src="/images/white-logo.svg"
              alt="logo"
              width={150}
              height={100}
            />
          </Link>
          <div className="flex flex-col justify-between  lg:items-center">
            <ul className="mt-4 flex items-center gap-3">
              <li className="cursor-pointer transition duration-300 ease-in-out hover:scale-110">
                <a
                  target="_blank"
                  href={FACEBOOK_URL}
                  rel="noopener noreferrer"
                >
                  <Image
                    src="/images/fb.svg"
                    alt="facebook"
                    width={30}
                    height={30}
                  />
                </a>
              </li>
              <li className="cursor-pointer transition duration-300 ease-in-out hover:scale-110">
                <a
                  target="_blank"
                  href={LINKEDIN_URL}
                  rel="noopener noreferrer"
                >
                  <Image
                    src="/images/LinkedIN.svg"
                    alt="linkedin"
                    width={30}
                    height={30}
                  />
                </a>
              </li>
            </ul>
            <p className="mt-5 hidden text-sm font-light sm:block lg:text-center">
              {t("footer.copyright")}
            </p>
          </div>
        </div>
      </div>
      <p className="mt-10 px-4 pb-5 text-center text-sm font-light text-white sm:hidden md:px-0">
        {t("footer.copyright")}
      </p>
    </footer>
  );
};
